import domReady from '@wordpress/dom-ready';

domReady(() => {
  const navItems = document.querySelectorAll(
    '.site-header__inner .nav-item__link'
  );

  if (0 < navItems.length) {
    navItems.forEach((navItem) => {
      navItem.setAttribute('data-text', navItem.textContent);
    });
  }
});
