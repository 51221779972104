import domReady from '@wordpress/dom-ready';

domReady(() => {
  const btns = document.querySelectorAll(
    '.btn, .btn--outline, .btn--icon, .wp-block-button__link'
  );

  if (0 < btns.length) {
    btns.forEach((btn) => {
      btn.setAttribute('data-text', btn.textContent);
    });
  }
});
